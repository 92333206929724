<template>
  <b-row>
    <b-col lg="12" sm="12">
        <template>
          <b-overlay :show="loader">
            <b-row>
              <b-col lg="12" sm="12">
                  <b-table-simple v-if="Object.values(detailsData).length > 0" bordered hover small class="text-center">
                    <b-tr>
                      <b-th style="width:20%" class="text-right">{{ $t('ditfConfig.bank_name') }}</b-th>
                      <b-td style="width:80%" class="text-left">{{ $i18n.locale == 'bn' ? detailsData.bank_name_bn : detailsData.bank_name_en }}</b-td>
                    </b-tr>
                  </b-table-simple>
                  <b-table-simple bordered hover small>
                    <thead>
                      <tr>
                        <b-th colspan="12" class="text-center p-2" style="background: #B6D0E2 ">{{ $t('ditfConfig.bank_branches') }}</b-th>
                      </tr>
                      <tr class="bg-primary text-center">
                        <th>{{ $t('globalTrans.sl_no') }}</th>
                        <th>{{ $t('ditfConfig.branch_name') }}</th>
                        <th>{{ $t('globalTrans.address') }}</th>
                      </tr>
                    </thead>
                    <b-tbody v-if="Object.values(branch).length > 0">
                        <b-tr v-for="(item, index) in branch" :key="index" class="text-center">
                            <b-td>{{ $n(index + 1) }}</b-td>
                            <b-td>{{ $i18n.locale == 'bn' ? item.text_bn : item.text_en }}</b-td>
                            <b-td>{{ $i18n.locale == 'bn' ? item.address_bn : item.address }}</b-td>
                        </b-tr>
                    </b-tbody>
                  </b-table-simple>
              </b-col>
            </b-row>
          </b-overlay>
        </template>
    </b-col>
  </b-row>
</template>
<script>
export default {
    name: 'Details',
    props: ['id', 'bankId'],
    data () {
      return {
      valid: null,
      loading: false,
      errors: [],
      detailsData: [],
      branch: []
      }
    },
    created () {
    if (this.id) {
      const tmp = this.getBankInfo()
      this.detailsData = tmp
      this.branch = this.getBranchList()
    }
    },
    methods: {
      getBranchList () {
        const branch = this.$store.state.CommonService.commonObj.branchList.filter(obj => obj.bank_id === this.bankId)
        // if (typeof branch !== 'undefined') {
        //   return this.$i18n.locale === 'en' ? branch.text_en : branch.text_bn
        // } else {
        //   return ''
        // }
        return branch
      },
      getBankInfo () {
        const tmpData = this.$store.state.list.find(item => item.id === this.id)
        return JSON.parse(JSON.stringify(tmpData))
      }
    }
}
</script>
