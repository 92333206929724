<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Bank Name" vid="bank_name" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="bank_name" label-cols-sm="3">
                <template v-slot:label>
                  {{ $t('ditfConfig.bank_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-checkbox-group
                  v-model="bank_ids"
                  :options="bankList"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-checkbox-group>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="button" @click="saveUpdate()" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { bankInfoStore, bankDetails } from '../../api/routes'
export default {
  name: 'Form',
  props: [],
  data () {
    return {
      valid: null,
      saveBtnName: this.$t('globalTrans.save'),
      errors: [],
      bank_ids: []
    }
  },
  created () {
    this.getEditData()
  },
  computed: {
    bankList () {
      return this.$store.state.CommonService.commonObj.bankList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    async saveUpdate () {
      const obj = {
        selected_banks: this.bank_ids
      }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }

        result = await RestApi.postData(internationalTradeFairServiceBaseUrl, bankInfoStore, obj)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })

          // toaster message
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-form')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
    },
    async getEditData () {
      let result = null
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      result = await RestApi.getData(internationalTradeFairServiceBaseUrl, bankDetails, null)
      if (result.success) {
        this.bank_ids = result.data
        this.saveBtnName = result.data.length ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
      } else {
        this.bank_ids = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    }
  }
}
</script>
