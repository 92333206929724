// Business Type Routes......
const businessType = '/ditf-configuration/business-type/'
export const businessTypeList = businessType + 'list'
export const businessTypeStore = businessType + 'store'
export const businessTypeUpdate = businessType + 'update'
export const businessTypeToggle = businessType + 'toggle-status'

// fair calendar routes...
const fairCalendar = '/ditf-configuration/fair-calendar/'
export const fairCalendarList = fairCalendar + 'list'
export const fairCalendarStore = fairCalendar + 'store'
export const fairCalendarUpdate = fairCalendar + 'update'
export const fairCalendarToggle = fairCalendar + 'toggle-status'

// stall category routes...
const stallCategory = '/ditf-configuration/stall-category/'
export const stallCategoryList = stallCategory + 'list'
export const stallCategoryStore = stallCategory + 'store'
export const stallCategoryUpdate = stallCategory + 'update'
export const stallCategoryShow = stallCategory + 'show'
export const stallCategoryToggle = stallCategory + 'toggle-status'

// stall Info routes...
const stallInfo = '/ditf-configuration/stall-information/'
export const stallInfoList = stallInfo + 'list'
export const stallInfoStore = stallInfo + 'store'
export const stallInfoUpdate = stallInfo + 'update'
export const stallInfoShow = stallInfo + 'show'
export const stallInfoToggle = stallInfo + 'toggle-status'

// security money routes...
const securityMoney = '/ditf-configuration/security-money/'
export const securityMoneyList = securityMoney + 'list'
export const securityMoneyStore = securityMoney + 'store'
export const securityMoneyUpdate = securityMoney + 'update'
export const securityMoneyToggle = securityMoney + 'toggle-status'

// security money routes...
const bankInfo = '/ditf-configuration/bank-info/'
export const bankInfoList = bankInfo + 'list'
export const bankInfoStore = bankInfo + 'store'
export const bankDetails = bankInfo + 'details'
export const bankInfoToggle = stallInfo + 'toggle-status'
